const request = (url: string, body: string) => {
	if (fetch) {
		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body
		});
	} else {
		console.log("fetch is not support");
	}
};

export const reportBot = (url: string, body: string) => {
	if (typeof window !== "undefined" && "requestIdleCallback" in window) {
		window.requestIdleCallback(() => {
			request(url, body);
		});
	} else {
		setTimeout(() => {
			request(url, body);
		}, 0);
	}
};
