// import * as module from "fs-design";
import lazyPlugin from "vue3-lazy";
// import { loadingDirective } from "fs-design"

// interface Component {
// 	name: string;
// }

// interface Module {
// 	[key: string]: Component;
// }
export default defineNuxtPlugin(nuxtApp => {
	// nuxtApp.vueApp.use(loadingDirective);
	nuxtApp.vueApp.use(lazyPlugin, {
		loading: "https://resource.fs.com/mall/generalImg/20231218153703710okg.jpg", // 图片加载时默认图片
		error: "https://resource.fs.com/mall/generalImg/20231218153703710okg.jpg" // 图片加载失败时默认图片
	});
	// Object.keys(module).forEach((key: string) => {
	// 	const component = (module as unknown as Module)[key] as Component;
	// 	if (!nuxtApp.vueApp.component(component.name)) {
	// 		nuxtApp.vueApp.component(component.name, component);
	// 	}
	// });
});
