import type { RouterOptions } from "nuxt/schema";
// 点击分页返回顶部
export default <RouterOptions>{
	scrollBehavior(to, from, savedPosition) {
		// 在按下前进/后退按钮，就会触发这个方法实现前进后退功能
		if (savedPosition) {
			return savedPosition;
		} else {
			const pathArr = ["/products_support.html"];
			if (to.path === from.path && pathArr.includes(to.path)) {
				return false;
			}
			return { top: 0 };
		}
	}
};
