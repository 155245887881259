<template>
	<div class="error_page error_page_500">
		<div class="logo_box">
			<a class="logo" :href="localeLink('/')">
				<img src="https://img-en.fs.com/includes/templates/fiberstore/images/fs-new/common/logo.svg" alt="FS LOGO" />
			</a>
		</div>

		<div class="error_msg">
			<h2 class="title">{{ localeLang("error.title") }}</h2>
			<p class="info">{{ localeLang("error.reason500") }}</p>
			<p
				class="info"
				v-html="
					localeLang('error.goTo').replace('XXXX', localeLink('/')).replace('YYYY', localeLink('/contact_us.html'))
				"></p>
		</div>
	</div>
</template>

<script setup>
const localeLang = useLocaleLang();
const localeLink = useLocaleLink();
</script>

<style lang="scss" scoped>
.error_page {
	width: 100%;
	max-width: 864px;
	margin: 84px auto 0 auto;
	@media (max-width: 768px) {
		padding: 0 16px;
	}
	.logo_box {
		.logo {
			display: inline-block;
			width: 72px;
			height: 35px;
			img {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
	}
	.error_msg {
		margin-top: 126px;
		color: $textColor1;
		text-align: center;
		.title {
			font-weight: 600;
			@include font40;
			margin-bottom: 30px;
		}
		.info {
			@include font16;
			margin-bottom: 8px;
			> a {
				color: $textColor6;
			}
		}
	}
}
</style>
