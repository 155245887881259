const fixScroll = (function (bodyClass) {
	let count = 0;
	return {
		fixed: function () {
			if (count < 1) {
				document.body.classList.add(bodyClass);
			}
			count += 1;
		},
		unfixed: function () {
			count -= 1;
			if (count < 1) {
				document.body.classList.remove(bodyClass);
				count = 0;
			}
		},
		reset: function () {
			count = 0;
			document.body.classList.remove(bodyClass);
		}
	};
})("fixScroll");

export default fixScroll;
