import { createApp, nextTick, ref } from "vue";
import type { DirectiveBinding } from "vue";
import { FsLoading } from "fs-design";
import { addClass, removeClass } from "@/utils/utils";
import fixScroll from "@/utils/fixScroll";
const relativeClass = "loading-directive-relative";

function append(el: HTMLElement & { instance: any }) {
	const style = getComputedStyle(el);
	if (!["absolute", "fixed", "relative"].includes(style.position)) {
		addClass(el, relativeClass);
	}
	el.appendChild(el.instance.$el);
}

function remove(el: HTMLElement & { instance: any }) {
	removeClass(el, relativeClass);
	el.removeChild(el.instance.$el);
}

export default defineNuxtPlugin(nuxtApp => {
	nuxtApp.vueApp.directive("loading", {
		mounted(el: HTMLElement & { instance: any }, binding: DirectiveBinding) {
			const app = createApp(FsLoading, {
				dark: !!binding.modifiers.dark,
				fullscreen: !!binding.modifiers.fullscreen,
				small: !!binding.modifiers.small,
				large: !!binding.modifiers.large,
				size: binding.arg
			});

			const instance = app.mount(document.createElement("div"));
			el.instance = instance;
			if (binding.value) {
				nextTick(() => {
					append(el);
					if (binding.modifiers.fullscreen) {
						fixScroll.fixed();
					}
				});
			}
		},
		updated(el: HTMLElement & { instance: any }, binding: DirectiveBinding) {
			console.log("bbbbbbbbbbbb");
			if (binding.oldValue !== binding.value) {
				if (binding.value) {
					nextTick(() => {
						append(el);
						if (binding.modifiers.fullscreen) {
							fixScroll.fixed();
						}
					});
				} else {
					nextTick(() => {
						remove(el);
						if (binding.modifiers.fullscreen) {
							fixScroll.unfixed();
						}
					});
				}
			}
		},
		unmounted(el: HTMLElement & { instance: any }, binding: DirectiveBinding) {
			if (el.instance) {
				if (binding.value) {
					nextTick(() => {
						remove(el);
						if (binding.modifiers.fullscreen) {
							fixScroll.unfixed();
						}
					});
				}
			}
		},
		getSSRProps(binding: DirectiveBinding) {
			// console.log("ddddddddddddddd");
			// console.log(binding);
			return {};
		}
	});
});
