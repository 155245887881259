export function isString(s: any) {
	return Object.prototype.toString.call(s) === "[object String]";
}
export function isNumber(s: number) {
	return typeof s === "number" && !isNaN(s);
}

export function isObject(obj: any) {
	return Object.prototype.toString.call(obj) === "[object Object]";
}

export function isFunction(fn: any) {
	return Object.prototype.toString.call(fn) === "[object Function]";
}

export function isNullObject(obj: {}) {
	if (Object.keys(obj).length === 0) {
		return true;
	}
	return false;
}

export function isFormData(t: any) {
	return Object.prototype.toString.call(t) === "[object FormData]";
}
