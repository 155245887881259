//	具体业务相关类型，需要业务相关人员补充，比如下列any类型，需要替换成具体类型
interface UserState {
	isLogin: number;
	isFirstLogin: number;
	userInfo: any;
	isCNRegion: boolean;
	managerInfo: any;
	system_messages: any;
	isShowMore: boolean;
	systemMessagesUnread: number;
	systemMessages: any[];
	companyInfo: any;
	isCompanyAccount: boolean;
	showPoInvoice: boolean;
	isFetch: boolean;
}
export const useUserStore = defineStore("user", {
	state: (): UserState => {
		return {
			isLogin: 0, // 登录状态 0：未登录   1：已登录,
			isFirstLogin: 0, // 是否是第一次登录
			userInfo: { isCompanyOrganizationUser: null }, // 用户信息
			isCNRegion: false,
			managerInfo: null,
			system_messages: null,
			isShowMore: false,
			systemMessagesUnread: 0,
			systemMessages: [],
			companyInfo: {},
			isCompanyAccount: false,
			showPoInvoice: false,
			isFetch: false
		};
	},
	getters: {
		gaLoginString: state => {
			// false为正常用户   true位公司员工
			let isStaff = false;
			const devideLang = useHtmlLang() || "";

			const mails = [
				"@fiberstore.com",
				"@fs.com",
				"@szyuxuan.com",
				"@feisu.com",
				"@ruijie.com.cn",
				"@centecnetworks.com",
				"@qq.com",
				"@163.com",
				"@126.com"
			];
			if (state.isLogin) {
				let customers_email_address = state.userInfo?.customers_email_address || "";
				const startIndex = customers_email_address.indexOf("@");
				const endIndex = customers_email_address.length;
				customers_email_address = customers_email_address.substring(startIndex, endIndex);
				isStaff = mails.includes(customers_email_address);
			} else {
				isStaff = ["zh-cn", "zh"].includes(devideLang);
			}
			return isStaff ? "internalTraffic" : "nonInternalTraffic";
		}
	},
	actions: {
		async getUserInfo() {
			const { data, error } = await useRequest.post("/api/user/info?getMore=1&get_system_messages=1");
			this.isFetch = true;
			if (data && data.value) {
				this.isLogin = data.value.data.isLogin;
				this.isFirstLogin = data.value.data.isFirstLogin;
				this.userInfo = data.value.data.user || null;
				this.managerInfo = data.value.data.managerInfo || null;
				this.isCNRegion = data.value.data.isCNRegion;
				this.systemMessagesUnread = data.value.data.system_messages?.is_list_un_read;
				this.systemMessages = data.value.data.system_messages?.messages;
				this.isShowMore = data.value.data.isShowMore;
				this.companyInfo = data.value.data.user?.companyInfo;
				this.isCompanyAccount = data.value.data.user?.isCompanyOrganizationUser;
				this.showPoInvoice = data.value.data.user?.showPoInvoice;
				console.log(data.value, "111111");
			}
		}
		//
	}
});
