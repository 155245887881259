export const useHtmlLang = () => {
	const websiteStore = useWebsiteStore();
	const { website, iso_code } = storeToRefs(websiteStore);
	if (["cn", "hk", "mo", "tw", "uk"].includes(website.value)) {
		if (website.value === "cn") {
			return "zh-CN";
		} else if (website.value === "hk") {
			return "zh-HK";
		} else if (website.value === "mo") {
			return "zh-MO";
		} else if (website.value === "tw") {
			return "zh-TW";
		} else if (website.value === "uk") {
			return "en-UK";
		}
	} else {
		let s = website.value;
		if (s === "jp") {
			s = "ja";
		} else if (s === "mx") {
			s = "es";
		} else if (["de-en", "au", "sg"].includes(website.value)) {
			s = "en";
		}
		return `${s}-${iso_code.value}`;
	}
};
