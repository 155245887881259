// import { useDeviceStore } from '@/stores/device';
import detectDevice from "@/utils/detectDevice";

export default defineNuxtPlugin(_nuxtApp => {
	const deviceStore = useDeviceStore();
	const userStore = useUserStore();
	const headerStore = useHeaderStore();
	const websiteStore = useWebsiteStore();
	const preWebsiteInfo: any = useCookies("preWebsiteInfo");
	const fs_websiteinfo = useCookies("fs_websiteinfo");
	if (process.server) {
		const headers = useRequestHeaders();
		const ua = headers["user-agent"] || "";
		const deviceObj = detectDevice(headers["user-agent"] || "");
		const supportWebp: boolean = (headers && headers.accept && headers.accept.includes("image/webp")) || false;
		const isChromeLighthouse: boolean = /lighthouse/i.test(ua);
		let screenWidth: number;
		let screenHeight: number;
		if (deviceObj.device === "pc") {
			screenWidth = 1920;
			screenHeight = 1080;
		} else if (deviceObj.device === "pad") {
			screenWidth = 1024;
			screenHeight = 768;
		} else if (deviceObj.device === "mobile") {
			screenWidth = 414;
			screenHeight = 736;
		}

		deviceStore.$patch(state => {
			state.browser = deviceObj.browser;
			state.browserZH = deviceObj.browserZH as string;
			state.browserVersion = deviceObj.browserVersion;
			state.os = deviceObj.os;
			state.osVersion = deviceObj.osVersion;
			state.device = deviceObj.device;
			state.engine = deviceObj.engine;
			state.supportWebp = supportWebp;
			state.screenWidth = screenWidth;
			state.screenHeight = screenHeight;
			state.isChromeLighthouse = isChromeLighthouse;
		});
		// userStore.getUserInfo();
		console.log("getHeaderData_start");
		headerStore.getHeaderData();
		console.log("getHeaderData_end");
	}

	if (process.client) {
		function initDevice() {
			const w = document.documentElement.clientWidth;
			const h = document.documentElement.clientHeight;
			let d: string;
			if (w > 1024) {
				d = "pc";
			} else if (w > 768 && w <= 1024) {
				d = "pad";
			} else if (w <= 768) {
				d = "mobile";
			}
			deviceStore.$patch(state => {
				state.device = d;
				state.screenWidth = w;
				state.screenHeight = h;
			});
		}
		function initGRecaptchaJs() {
			const sitekey = "6Lf874IpAAAAACYfn7X2StpklJzoaN3JehO956Xc";
			const script = document.createElement("script");
			script.setAttribute("data-callType", "callScript");
			script.src = `https://www.google.com/recaptcha/api.js?render=${sitekey}`;
			script.onerror = () => {
				const s = document.createElement("script");
				s.setAttribute("data-callType", "callScript");
				s.src = `https://www.recaptcha.net/recaptcha/api.js?render=${sitekey}`;
				document.head.appendChild(s);
			};
			document.head.appendChild(script);
		}
		setTimeout(() => {
			initDevice();
			initGRecaptchaJs();
		}, 1000);
		window.addEventListener("resize", () => {
			initDevice();
			useHeaderHeight();
		});
	}
});
