// canvas指纹
import CryptoJS from "crypto-js";
let md5 = "";
export const getCanvasFingerprinting = () => {
	if (!import.meta.client) return;
	if (md5) return md5;
	try {
		const canvas = document.createElement("canvas");
		canvas.style.display = "none";
		document.body.appendChild(canvas);
		const ctx = canvas.getContext("2d");
		if (!ctx) return;
		// Text with lowercase/uppercase/punctuation symbols
		const txt = "FS.com Platform FingerTest";
		ctx.textBaseline = "top";
		// The most common type
		ctx.font = "14px 'Arial'";
		ctx.textBaseline = "alphabetic";
		ctx.fillStyle = "#f60";
		ctx.fillRect(125, 1, 62, 20);
		// Some tricks for color mixing to increase the difference in rendering
		ctx.fillStyle = "#069";
		ctx.fillText(txt, 2, 15);
		ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
		ctx.fillText(txt, 4, 17);
		const url = canvas.toDataURL();
		md5 = CryptoJS.MD5(url).toString();
		document.body.removeChild(canvas);
		return md5;
	} catch (error: any) {
		console.log("fingerprinting-error:", error.message);
	}
};
