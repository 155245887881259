import type {
	Category,
	BussinessClassifyElement,
	PokedexClassify,
	FooterLink,
	classifyNewItem,
	HotSearchList
} from "../types/interfaceHeader";
import fixScroll from "@/utils/fixScroll";
interface HeaderState {
	menuData: any[];
	category: Category;
	classify: PokedexClassify[];
	classifyNew: classifyNewItem[];
	footerClassify: BussinessClassifyElement[];
	footerBussinessClassify: BussinessClassifyElement[];
	ausAcknowledges: any;
	email: string;
	phone: string;
	notice: {
		title: string;
		url: string;
	};
	fsSite: {
		title: string;
		url: string;
	};
	footerLinks: FooterLink[];
	right: string;
	right_m: string;
	pc_header_height: number;
	m_header_height: number;
	m_menu_expand: boolean;
	m_header_right_show: boolean;
	header_search_show: boolean;
	m_header_account_show: boolean;
	m_header_notifications_show: boolean;
	m_header_cart_show: boolean;
	m_header_ctn_fixed: boolean;
	showCookieTip: boolean;
	isShowHoliday: boolean;
	holidayContent: string;
	hotSearchList: HotSearchList[];
	hotChangePending: boolean;
	hotSearchPage: number;
}

export const useHeaderStore = defineStore("header", {
	state: (): HeaderState => {
		return {
			menuData: [],
			category: {
				title: "",
				data: []
			} as Category,
			classify: [],
			classifyNew: [],
			footerClassify: [],
			footerBussinessClassify: [],
			ausAcknowledges: {},
			email: "",
			phone: "",
			notice: {
				title: "",
				url: ""
			},
			fsSite: {
				title: "",
				url: ""
			},
			footerLinks: [],
			right: "",
			right_m: "",
			pc_header_height: 0,
			m_header_height: 0,
			m_menu_expand: false,
			m_header_right_show: true,
			header_search_show: false,
			m_header_account_show: false,
			m_header_notifications_show: false,
			m_header_cart_show: false,
			m_header_ctn_fixed: false,
			showCookieTip: false,
			isShowHoliday: false,
			holidayContent: "",
			hotSearchList: [],
			hotChangePending: false,
			hotSearchPage: 0
		};
	},
	getters: {
		firstCateIds: state => {
			const ids: number[] = [];
			if (state.category && state.category.data && state.category.data.length) {
				state.category.data.forEach(item => {
					ids.push(item.categories_id);
				});
			}
			return ids;
		}
	},
	actions: {
		showSideBar() {
			fixScroll.reset();
			this.m_header_account_show = false;
			this.m_header_notifications_show = false;
			this.m_header_cart_show = false;
			this.header_search_show = false;
			this.m_menu_expand = true;
			fixScroll.fixed();
		},
		hideSideBar() {
			this.m_menu_expand = false;
			fixScroll.unfixed();
		},
		showSearchBox() {
			fixScroll.reset();
			this.m_header_account_show = false;
			this.m_header_notifications_show = false;
			this.m_header_cart_show = false;
			this.m_menu_expand = false;
			this.header_search_show = true;
			fixScroll.fixed();
		},
		hideSearchBox() {
			this.header_search_show = false;
			fixScroll.unfixed();
		},
		hideAllSideBar() {
			fixScroll.reset();
			this.m_header_notifications_show = false;
			this.m_header_cart_show = false;
			this.m_menu_expand = false;
			this.m_header_account_show = false;
			this.header_search_show = false;
		},
		showMAccount() {
			fixScroll.reset();
			this.m_header_notifications_show = false;
			this.m_header_cart_show = false;
			this.m_menu_expand = false;
			this.header_search_show = false;
			this.m_header_account_show = true;
			fixScroll.fixed();
		},
		hideMAccount() {
			this.m_header_account_show = false;
			fixScroll.unfixed();
		},
		showMNotifications() {
			fixScroll.reset();
			this.m_header_account_show = false;
			this.m_header_cart_show = false;
			this.m_menu_expand = false;
			this.header_search_show = false;
			this.m_header_notifications_show = true;
			fixScroll.fixed();
		},
		hideMNotifications() {
			this.m_header_notifications_show = false;
			fixScroll.unfixed();
		},
		showMHeaderCart() {
			fixScroll.reset();
			this.m_header_account_show = false;
			this.m_header_notifications_show = false;
			this.m_menu_expand = false;
			this.header_search_show = false;
			this.m_header_cart_show = true;
			fixScroll.fixed();
		},
		hideMHeaderCart() {
			this.m_header_cart_show = false;
			fixScroll.unfixed();
		},
		async getHeaderData() {
			// const { data, error } = await useRequest.get("/api/header", {
			// 	data: { showType: 2 }
			// });
			const { data, error } = await useRequest.post(`/static`, {
				data: {
					method: "GET",
					url: `/api/header`,
					filterId: "header", // 标识字段
					moduleName: "header", // 模块名称
					params: {
						showType: 2
					}
				}
			});
			console.log("header");
			if (data && data.value) {
				const { category, classify, classifyNew, footer, tip } = data.value.data;
				this.menuData = [category, ...classify] as any;
				this.category = category;
				this.classifyNew = classifyNew;
				this.footerClassify = footer.classify || [];
				this.footerBussinessClassify = footer.bussinessClassify || [];
				this.ausAcknowledges = footer.fs_acknowledges;
				this.email = tip.email || "";
				this.phone = tip.phone || "";
				this.footerLinks = tip.footerLinks || "";
				this.fsSite = tip.fsSite || {};
				this.notice = tip.notice || {};
				this.right = tip.right || "";
				this.right_m = tip.right_m || "";
				if (classify && classify.length) {
					classify.forEach((item: any) => {
						if (item && item.solution_data_new && item.solution_data_new.list) {
							item.data = item.solution_data_new.list;
						}
					});
				}
				this.classify = classify;
			}
			if (error && error.value) {
				console.log("eeeeeeeeeee");
				console.log(error);
			}
		},
		async getHoliday() {
			const route = useRoute();
			console.log("____");
			console.log(route);
			const { data, error } = await useRequest.get("/api/message/noticeMessageIndex");
			if (data.value && data.value.data && data.value.data.content) {
				this.holidayContent = data.value.data.content;
				if (route && route.name && (/^home/g.test(route.name as string) || /^category/g.test(route.name as string))) {
					this.isShowHoliday = true;
					useHeaderHeight();
				}
			}
		},
		async getSearchHot() {
			if (!this.hotChangePending) {
				this.hotChangePending = true;
				const { data, error } = await useRequest.get("/api/search/hotSearch", {
					data: {
						page: this.hotSearchPage + 1
					}
				});
				this.hotChangePending = false;
				if (data.value && data.value.data) {
					this.hotSearchPage += 1;
					this.hotSearchList = data.value.data?.hot_data;
					if (this.hotSearchPage === data.value.data?.total_page) {
						this.hotSearchPage = 0;
					}
				}
			}
		}
	}
});
