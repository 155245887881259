// @ts-nocheck

import { Base64 } from "js-base64";
import CryptoJS from "crypto-js";
import { FsMessage } from "fs-design";
import header from "../../fs/feisu-front/src/store/header";
import { isObject } from "@/utils/types";
import { getCanvasFingerprinting } from "@/utils/canvasFingerprinting";

function genNonce(num: number) {
	const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
	let str = "";
	for (let i = 0; i < num; i++) {
		const pos = Math.round(Math.random() * (chars.length - 1));
		str += chars[pos];
	}
	return str;
}

const request = (url: string, options?: any): Promise<any> => {
	const nuxtApp = useNuxtApp();
	const customOptions = options || {};
	const websiteStore = useWebsiteStore();
	const userStore = useUserStore();
	const supportWebp = useDeviceStore().supportWebp;
	const { $i18n } = useNuxtApp();
	const runtimeConfig = useRuntimeConfig();
	const route = useRoute();
	const localeLink = useLocaleLink();
	const token = useCookies("token_new");
	const cartId = useCookies("cartId");
	const website = useCookies("website");
	const iso_code = useCookies("iso_code");
	const currency = useCookies("currency");
	const language = useCookies("language");
	const fs_websiteinfo = useCookies("fs_websiteinfo");
	// console.log("request ");
	// console.log(websiteStore.website);
	// console.log(websiteStore.iso_code);
	// console.log(websiteStore.currency);
	// console.log(websiteStore.language);
	// console.log("request");
	// let requestUrl = url
	let baseURL = "";
	if (!customOptions.isBaseUrl && url !== "/static") {
		// customOptions.baseURL = /^\/api/.test(url)
		// 	? runtimeConfig.public.VITE_NUXT_FS_API
		// 	: runtimeConfig.public.VITE_NUXT_CMS_API;
		if (/^\/api/.test(url)) {
			if (runtimeConfig.public.VITE_NUXT_ENV === "PROD_CN" && ["hk", "tw", "mo"].includes(websiteStore.website)) {
				baseURL = runtimeConfig.public.VITE_NUXT_FS_HK_API || runtimeConfig.public.VITE_NUXT_FS_API;
			} else {
				if (websiteStore.website === "cn") {
					baseURL = runtimeConfig.public.VITE_NUXT_CN_FS_API || runtimeConfig.public.VITE_NUXT_FS_API;
				} else {
					baseURL = runtimeConfig.public.VITE_NUXT_FS_API;
				}
			}
		} else if (/^\/tools/.test(url)) {
			baseURL = runtimeConfig.public.VITE_NUXT_TOOLS_API;
		} else {
			if (websiteStore.website === "cn") {
				baseURL = runtimeConfig.public.VITE_NUXT_CN_CMS_API || runtimeConfig.public.VITE_NUXT_CMS_API;
			} else {
				baseURL = runtimeConfig.public.VITE_NUXT_CMS_API;
			}
		}
	}
	customOptions.baseURL = baseURL;
	const { locale } = $i18n;
	const timestamps = new Date().getTime();
	const nonce = genNonce(16);
	const apiKey = "yuxuanxuanpc";
	const payload = "[]";
	const s = timestamps + nonce + payload;
	const h = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, "yuxuan3507");
	h.update(s);
	const signature = Base64.encode(CryptoJS.enc.Hex.stringify(h.finalize()));
	const shareUrlId = route?.query?.shareUrlId || "";
	// let contentType = "application/json";
	// if (customOptions.method === "POST" && customOptions.body && customOptions.body instanceof FormData) {
	// 	contentType = "application/x-www-form-urlencoded";
	// }

	customOptions.headers = Object.assign(
		{},
		options && options.headers && isObject(options.headers) ? options.headers : {},
		{
			Accept: "application/json",
			// "Content-Type": contentType,
			timestamps,
			nonce,
			apiKey,
			clientSignature: signature
		}
	);

	if (shareUrlId) {
		customOptions.headers.shareUrlId = shareUrlId;
	}
	// options.server = false
	// options.lazy = false
	if (token.value || route.query.token) {
		customOptions.headers.Authorization = token.value || route.query.token;
	}
	if (cartId.value) {
		customOptions.headers.cartId = cartId.value;
	}

	const canvasFingerprinting = getCanvasFingerprinting();
	if (canvasFingerprinting) {
		customOptions.headers["fs-client-id"] = canvasFingerprinting;
	}

	const webSiteInfo = {
		isCookie: true,
		website: websiteStore.website,
		iso_code: websiteStore.iso_code,
		currency: websiteStore.currency,
		language: websiteStore.language,
		language_id: websiteStore.language_id
	};

	customOptions.headers.webSiteInfo = Base64.encode(JSON.stringify(webSiteInfo));
	customOptions.headers.Supportwebp = supportWebp;
	// return new Promise((resolve, reject) => {
	return useFetch(url, {
		...customOptions,
		onRequest({ request, options }) {
			// console.log(url);
		},
		onRequestError({ error }) {
			// console.log("error:" + url);
			// reject(error);
		},
		onResponse({ response, options }) {
			// console.log("onResponseonResponseonResponseonResponse");
			// console.log(response);
			// console.log("onResponseonResponseonResponseonResponse")
			const res = response._data;

			if (response.headers.get("authorization") && process.client) {
				token.value = response.headers.get("authorization");
			}
			if (response.headers.get("cartid") && process.client) {
				cartId.value = response.headers.get("cartid");
			}
			if (response.headers.get("clientip")) {
				websiteStore.$patch(state => {
					state.clienIp = response.headers.get("clientip");
				});
			}
			if (response.headers.get("websiteinfo") && JSON.parse(Base64.decode(response.headers.get("websiteinfo")))) {
				const responseWebsite = JSON.parse(Base64.decode(response.headers.get("websiteinfo")));

				fs_websiteinfo.value = responseWebsite;

				// console.log("resresres");
				// console.log(responseWebsite);
				// console.log("resresres");
				websiteStore.$patch(state => {
					state.website = responseWebsite.website;
					state.iso_code = responseWebsite.iso_code;
					state.currency = responseWebsite.currency;
					state.language = responseWebsite.language;
					state.country_name = responseWebsite.country_name;
					state.countries_id = responseWebsite.countries_id;
					state.language_id = responseWebsite.language_id;
					state.warehouse = responseWebsite.warehouse;
					state.isEuUnion = responseWebsite.isEuUnion;
					state.tel_prefix = responseWebsite.tel_prefix;
					state.timezone = responseWebsite.timezone;
					state.symbol = responseWebsite.symbol;
				});
				website.value = responseWebsite.website;
				iso_code.value = responseWebsite.iso_code;
				currency.value = responseWebsite.currency;
				language.value = responseWebsite.language;

				if (runtimeConfig.public.VITE_NUXT_ENV && ["PROD", "PROD_CN"].includes(runtimeConfig.public.VITE_NUXT_ENV)) {
					if (["PROD"].includes(runtimeConfig.public.VITE_NUXT_ENV)) {
						if (responseWebsite.website === websiteStore.website) {
							// do something
						} else {
							if (["cn", "hk", "tw", "mo"].includes(responseWebsite.website)) {
								let p = runtimeConfig.public.NODE_ENV === "production" ? `https://cn.fs.com` : ``;
								p += `/${responseWebsite.website}`;

								p += route.fullPath;
								p = localeLink(p, responseWebsite.website);
								// console.log("1111111111");
								// console.log(p);
								// console.log("1111111111");
								nuxtApp.runWithContext(() => navigateTo(p, { external: true }));
							} else {
								const p = localeLink(route.fullPath, responseWebsite.website);
								// console.log("22222222222");
								// console.log(p);
								// console.log("22222222222");
								nuxtApp.runWithContext(() => navigateTo(p, { external: true }));
							}
						}
					} else {
						if (responseWebsite.website === websiteStore.website) {
							// do something
						} else {
							if (["cn", "hk", "tw", "mo"].includes(responseWebsite.website)) {
								const p = localeLink(route.fullPath, responseWebsite.website);
								nuxtApp.runWithContext(() => navigateTo(p, { external: true }));
							} else {
								let p = runtimeConfig.public.NODE_ENV === "production" ? `https://www.fs.com` : ``;
								p += `/${responseWebsite.website}`;

								p += route.fullPath;
								p = localeLink(p, responseWebsite.website);

								nuxtApp.runWithContext(() => navigateTo(p, { external: true }));
							}
						}
					}
				} else {
					if (responseWebsite.website !== websiteStore.website) {
						if (process.server) {
							const p = localeLink(route.fullPath, responseWebsite.website);
							nuxtApp.runWithContext(() => navigateTo(p, { external: true }));
						}
					}
				}
			}
		},
		onResponseError({ request, response, options }) {
			console.log("onResponseErroronResponseErroronResponseError");
			console.log(response);
			console.log(response._data);
			console.log("onResponseErroronResponseErroronResponseError");

			const res = response._data;
			switch (res.code) {
				case 404:
					console.log("404");
					// navigateTo(localeLink(runtimeConfig.public.VITE_NUXT_DOMAIN + "/404.html"), { external: true });

					nuxtApp.runWithContext(() =>
						navigateTo(localeLink(runtimeConfig.public.VITE_NUXT_DOMAIN + "/404.html"), {
							external: true
						})
					);
					// reject(res);
					break;
				case 401:
					token.value = null;
					userStore.$reset();
					const r = route.fullPath;
					nuxtApp.runWithContext(() =>
						navigateTo(localeLink(runtimeConfig.public.VITE_NUXT_DOMAIN + `/login.html?redirect=${r}`), {
							external: true
						})
					);
					break;
				case 403:
					if (process.client && res?.message) {
						FsMessage({
							type: "danger",
							message: res?.message
						});
					}
					break;
				case 422:
					if (res.data && res.data.errors && isObject(res.data.errors)) {
						for (const attr in res.data.errors) {
							if (Array.isArray(res.data.errors[attr])) {
								res.data.errors[attr] = res.data.errors[attr].join(",");
							}
						}
					}
					break;
				case 429:
					nuxtApp.runWithContext(() =>
						navigateTo(localeLink(runtimeConfig.public.VITE_NUXT_DOMAIN + "/429.html"), {
							external: true
						})
					);
					break;
				case 500:
					nuxtApp.runWithContext(() =>
						navigateTo(localeLink(runtimeConfig.public.VITE_NUXT_DOMAIN + "/500.html"), {
							external: true
						})
					);
					break;
				case 400:
					break;
				default:
					nuxtApp.runWithContext(() =>
						navigateTo(localeLink(runtimeConfig.public.VITE_NUXT_DOMAIN + "/500.html"), {
							external: true
						})
					);
			}
		}
	});
	// });
};

class MyRequest {
	get<T>(url: string, options = {}): any {
		return request(url, { method: "GET", params: options?.data, ...options });
	}
	post<T>(url: string, options?: any): any {
		return request(url, { method: "POST", body: options?.data, ...options });
	}
}

export default new MyRequest();
