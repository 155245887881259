// import Monitor from "@fs-front/monitor";

import { reportBot } from "@/utils/reportBot";
interface reportPramas {
	fileModule?: string;
	author?: Array<string>;
}

const userIdMap: Record<string, string> = {
	ajay: "ou_fb408f4e5ff76bc93c36ef1a55b7b766",
	barry: "ou_ad5f0c25f013c2e23484c6d6ea1881ce",
	moses: "ou_f27609e6e81da51d9b6848f9b9eac982",
	Lilith: "ou_8de5a338ee4eeaf87231e72b28bd8416",
	ray: "ou_4c0164d63617cbe9a770ae352a5d5928",
	venchy: "ou_ddf20fbc45bd14f527b2da252a1ed380",
	jarvis: "ou_862f608ff74cca6d581495aa5e600ccd",
	jabin: "ou_46a6befa6ca2477ce1cde8a8d4094eaf",
	geralt: "ou_a2fe147e27ae30d83da5d050af3ffa88",
	hardy: "ou_11b883de8f77e0c2577dad22b069c60d",
	solo: "ou_88d4d8f645d7dc2b72001b7fbabe7071",
	anke: "ou_f86041a2dd9e608a74b5e8f9b9a543f6",
	pocketman: "ou_722e9f602da3020e818bc97046c0d67e",
	dean: "ou_bd255194bf05c7abc05555030ffdd351",
	legendary: "ou_e32bfd06f47253972744bfd86be50ac0"
};

const setReportAuthor = (users?: Array<string> | undefined) => {
	let s: string = "";
	if (users && users.length) {
		users.forEach(item => {
			s += `<at id=${userIdMap[item]}></at>`;
		});
	}
	return s;
};
export default function useReportError(pramas?: reportPramas) {
	const URL = useRequestURL();
	const websiteStore = useWebsiteStore();
	const userStore = useUserStore();
	const deviceStore = useDeviceStore();
	const runtimeConfig = useRuntimeConfig();
	// const monitor = null;

	const { website, iso_code, country_name, language, currency, symbol, isCn, isJp, isJpEn, isRussia, clienIp } =
		storeToRefs(websiteStore);
	const { isLogin, userInfo } = storeToRefs(userStore);

	const { browser, browserVersion, os, osVersion, screenWidth, screenHeight } = storeToRefs(deviceStore);

	console.log("1122334455");

	// if (runtimeConfig.public.NODE_ENV === "production" && ["RELEASE", "PROD"].includes(runtimeConfig.public.VITE_NUXT_ENV)) {

	const reportUrl = runtimeConfig.public.VITE_NUXT_FEISHU_BOT;
	// onErrorCaptured((error: Error, instance: any, info: string) => {

	return (err: any) => {
		const larkMessage = {
			msg_type: "interactive",
			card: {
				config: {
					wide_screen_mode: true,
					enable_forward: true,
					update_multi: true
				},
				header: {
					template: "red",
					title: {
						content: "平台商城前端bug汇报",
						tag: "plain_text"
					}
				},
				elements: [
					{
						tag: "div",
						text: {
							content: "可能出现错误的页面展示，请相关人员及时处理代码的bug\n",
							tag: "plain_text"
						}
					},
					{
						tag: "hr"
					},
					{
						tag: "div",
						text: {
							content: `报错页面链接：[${URL.href}](${URL.href})`,
							tag: "lark_md"
						}
					},
					{
						tag: "hr"
					},
					{
						tag: "div",
						text: {
							content: `报错类型：${process.server ? `服务端错误` : `客户端错误`}     ${clienIp.value ? `客户IP：${clienIp.value}` : ``}`,
							tag: "lark_md"
						}
					},
					{
						tag: "hr"
					},
					{
						tag: "div",
						text: {
							content: `站点国家：${country_name.value}   站点语言：${language.value}   站点货币：${currency.value}`,
							tag: "lark_md"
						}
					},
					{
						tag: "hr"
					},
					{
						tag: "div",
						text: {
							content: `登录状态：${isLogin.value ? `已登录` : `未登录`}     ${userInfo.value?.customers_email_address ? `客户邮箱：${userInfo.value?.customers_email_address}` : ``}   ${userInfo.value?.customers_id ? `customers_id：${userInfo.value?.customers_id}` : ``}`,
							tag: "lark_md"
						}
					},
					{
						tag: "hr"
					},
					{
						tag: "div",
						text: {
							content: `操作系统：${os.value}    操作系统版本：${osVersion.value}`,
							tag: "lark_md"
						}
					},
					{
						tag: "hr"
					},
					{
						tag: "div",
						text: {
							content: `浏览器：${browser.value}     浏览器版本：${browserVersion.value}`,
							tag: "lark_md"
						}
					},
					{
						tag: "hr"
					},
					{
						tag: "div",
						text: {
							content: `浏览器窗口大小：宽${screenWidth.value}  高${screenHeight.value}`,
							tag: "lark_md"
						}
					},
					{
						tag: "hr"
					}
				]
			}
		};
		if (pramas && (pramas.fileModule || pramas.author)) {
			larkMessage.card.elements.push({
				tag: "div",
				text: {
					content: `${pramas?.fileModule ? `报错模块：${pramas?.fileModule}` : ``}    ${pramas && pramas.author ? `相关开发人员：${setReportAuthor(pramas.author)}` : ``}`,
					tag: "lark_md"
				}
			});
			larkMessage.card.elements.push({
				tag: "hr"
			});
		}
		larkMessage.card.elements.push({
			tag: "div",
			text: {
				content: `错误信息：${err}`,
				tag: "lark_md"
			}
		});
		reportBot(reportUrl, JSON.stringify(larkMessage));
	};
	// }
}
