<template>
	<NuxtLayout>
		<NuxtPage />
	</NuxtLayout>
	<!-- <CookieTip /> -->
</template>

<script setup lang="ts">
import { loadGTM, loadFontCss, loadBD } from "@/utils/loadResource";

const websiteStore = useWebsiteStore();
const metaStore = useMeatStore();
const htmlLang = useHtmlLang();
const headerStore = useHeaderStore();
const userStore = useUserStore();
const cartStore = useCartStore();
const deviceStore = useDeviceStore();

const { title, commonMeta, verifyMeta, ogMeta, twitterMeta, commonLink, link } = storeToRefs(metaStore);
const { device, isChromeLighthouse } = storeToRefs(deviceStore);
const { website, country_name, iso_code, language, currency, symbol, warehouse, isCn } = storeToRefs(websiteStore);

const { isCNRegion } = storeToRefs(userStore);

const schemaOrganization = {
	"@context": "https://schema.org",
	"@type": "Organization",
	name: "FS.com",
	url: `https://www.fs.com/${website.value === "en" ? "website.value" : ""}`,
	logo: "https://img-en.fs.com/includes/templates/fiberstore/images/fs-new/common/logo.svg",
	address: [
		{
			"@type": "PostalAddress",
			contactOption: "https://schema.org/TollFree",
			contactType: "customer service",
			telephone: "1-888-468-7419",
			addressCountry: "US",
			addressLocality: "New Castle",
			addressRegion: "Delaware",
			postalCode: "19720",
			streetAddress: "380 Centerpoint Blvd"
		},
		{
			"@type": "PostalAddress",
			contactOption: "https://schema.org/TollFree",
			contactType: "customer service",
			telephone: "3-9693-3488 ",
			addressCountry: "AU",
			addressLocality: "Dandenong South",
			addressRegion: "Victoria",
			postalCode: "3175",
			streetAddress: "57-59 Edison Rd"
		},
		{
			"@type": "PostalAddress",
			contactOption: "https://schema.org/TollFree",
			contactType: "customer service",
			telephone: "3-5826-8305 ",
			addressCountry: "JP",
			addressLocality: "Ota-ku",
			addressRegion: "Tokyo",
			postalCode: "143-0006",
			streetAddress: "JS Progress Building, 4-1-23 Heiwajima"
		},
		{
			"@type": "PostalAddress",
			contactOption: "https://schema.org/TollFree",
			contactType: "customer service",
			addressCountry: "DE",
			addressLocality: "Neufahrn bei Munich",
			addressRegion: "Munich",
			postalCode: "85375",
			streetAddress: "NOVA Gewerbepark Building 7"
		},
		{
			"@type": "PostalAddress",
			contactOption: "https://schema.org/TollFree",
			contactType: "customer service",
			telephone: "6443 7951",
			addressCountry: "SG",
			postalCode: "339213",
			streetAddress: "30A Kallang Place"
		},
		{
			"@type": "PostalAddress",
			contactOption: "https://schema.org/TollFree",
			contactType: "customer service",
			telephone: "0 121 716 1755",
			addressCountry: "UK",
			addressLocality: "Aston",
			addressRegion: "Birmingham",
			postalCode: "B6 7FH",
			streetAddress: "Urban Express Park, Unit 8 Union Wy"
		}
	],
	founder: {
		"@type": "Person",
		name: "Daniel Xiang"
	},
	foundingDate: "2009-04-09",
	legalName: "FS.com"
};

console.log("app_app");
console.log(website.value);
useHead({
	htmlAttrs: {
		lang: htmlLang
	},
	// title,
	// meta: [...commonMeta.value, ...verifyMeta.value, ...ogMeta.value, ...twitterMeta.value],
	// link: [...commonLink.value, ...link.value],
	style: [loadFontCss(website.value)],
	script: [
		{
			type: "application/ld+json",
			children: JSON.stringify(schemaOrganization)
		}
	]
});

onMounted(async () => {
	if (website.value && website.value === "cn") {
		loadBD();
		const style = document.createElement("style");
		style.textContent = loadFontCss(website.value).children;
		document.head.appendChild(style);
	} else {
		if (!isChromeLighthouse.value) {
			loadGTM(website.value);
		}
	}
	await nextTick();
	setTimeout(async () => {
		await userStore.getUserInfo();
		if (device.value === "pc") {
			cartStore.getCart({
				isOnlyFetchCount: 1
			});
		}
	}, 0);
});
</script>

<style lang="scss" scoped></style>
