interface GaState {
	pageGroup: string;
	pageLocation: string;
	redirectedFrom: string;
}
export const useGaStore = defineStore("ga", {
	state: (): GaState => {
		return {
			pageGroup: "",
			pageLocation: "",
			redirectedFrom: ""
		};
	},
	actions: {}
});
